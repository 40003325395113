<template>
  <div class="d-flex" style="height: 100%">
    <div
      class="pa-2 mx-auto my-auto"
      :style="`text-align: center; width: ${
        $vuetify.breakpoint.width < 600 ? '50%' : '35%'
      }`"
    >
      <h1
        :class="`${
          $vuetify.breakpoint.width < 694 ? 'headline' : 'display-1'
        } font-weight-bold pb-10`"
      >
        {{ $t("loginPage.title") }}
      </h1>
      <v-form class="form" ref="loginForm">
        <v-row class="flex-column" justify="start" align="start">
          <v-col class="pa-0">
            <v-text-field
              :label="$t('loginPage.email')"
              :rules="emailRules"
              fill
              outlined
              v-model="email"
              style="width: 150%"
              @keyup.enter="submitHandler"
            ></v-text-field>
          </v-col>
          <v-col class="pa-0">
            <v-text-field
              :label="$t('loginPage.password')"
              type="password"
              :rules="passRules"
              fill
              outlined
              class="pt-4"
              v-model="password"
              style="width: 150%"
              :hide-details="password.length >= 4"
              @keyup.enter="submitHandler"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex pa-0 py-2">
            <v-btn
              to="/reset-password"
              text
              style="letter-spacing: 0px"
              class="text-capitalize"
            >
              {{ $t("loginPage.forgotPass") }}</v-btn
            >
          </v-col>
          <v-btn
            color="black"
            @click="submitHandler"
            style="color: white; letter-spacing: 0px"
            class="mt-6"
            block
            large
            :loading="isLoading"
          >
            {{ $t("loginPage.submit") }}
          </v-btn>
          <v-alert class="mt-4" width="100%" v-if="showErrorAlert" type="error">
            {{ errorMsg }}
          </v-alert>
        </v-row>
      </v-form>

      <v-btn
        v-show="$vuetify.breakpoint.width <= 1263"
        color="black"
        @click="$router.push('/signup')"
        width="100%"
        style="color: white; letter-spacing: 0px"
        class="mt-6 text-capitalize"
        text
      >
        {{ $t("loginPage.createAccountMobile") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || this.$t("loginPage.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("loginPage.emailValid"),
      ],
      passRules: [(v) => v.length >= 4 || this.$t("loginPage.passwordValid")],
      isLoading: false,
      showErrorAlert: false,
      errorMsg: "",
    };
  },
  methods: {
    submitHandler() {
      var isValid = this.$refs.loginForm.validate();
      if (isValid) this.login();
    },
    login() {
      this.showErrorAlert = false;
      this.isLoading = true;

      this.$store
        .dispatch("authModule/login", {
          body: {
            email: this.email.toLowerCase(),
            password: this.password,
          },
        })
        .then(() => {
          this.$router.push("/items");
          this.$store.dispatch("authModule/getAds");
        })
        .catch((err) => {
          if (err.response.data.data == "Invalid email or password") {
            this.showErrorAlert = true;
            this.errorMsg = this.$t("loginPage.wrong");
          }
          if (err.response.data.msg == "Invalid Password") {
            this.showErrorAlert = true;
            this.errorMsg = this.$t("loginPage.wrong");
          }

          if (err.response.data.msg == "EMAIL_NOT_VERIFIED") {
            this.showErrorAlert = true;
            this.errorMsg = this.$t("loginPage.not_verified");
          }
          if (err.response.data.data == "This user have been banned") {
            this.showErrorAlert = true;
            this.errorMsg = this.$t("loginPage.banned");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
